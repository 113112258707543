import React from "react"
import { Link } from "gatsby"

class PostPreview extends React.Component {
  render() {

    const {title, subTitle, teaser, date, type, contentfulImg} = this.props;
    const link = "/reiseberichte/"+this.props.slug;
    return (
      <div class="card post-box hover-box"
      style={{maxHeight: "1200px", minWidth: "400px", maxWidth: "800px", overflow: "hidden"}}>
      <Link to={link}>

        <div class="card-image">
          <div class="image-wrapper">
            <img src={contentfulImg.url} alt=''/>
          </div>
        </div>

        <div class="card-content">
          <div class="media">

            <div class="media-content">
              <h1 class="title is-4 has-text-primary has-text-weight-light">{title}</h1>
              <h2 class="subtitle has-text-black has-text-weight-light is-6">{subTitle}</h2>
            </div>

          </div>

          <div class="content">

            <div class="has-text-black has-text-weight-light text-clipped">
                {teaser}  
            </div>
            <hr class="hr"/>

            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-black has-text-weight-light ">
                  {date}
                </div>
              </div>

              <div class="column">
                <div class="has-text-right">
                {type === 'Kundenbericht' &&
                <Link to="/reiseberichte">
                  <span class="tag is-primary is-outlined is-rounded">#Kundenberichte</span>
                </Link>
                }
                </div>

                <div class="has-text-right">
                {type === 'Themenbericht' &&
                <Link to="/reiseberichte">
                  <span class="tag is-primary is-outlined is-rounded">#Themenurlaub</span>
                </Link>
                }
                </div>

                <div class="has-text-right">
                {type === 'Urlaubstipp' &&
                <Link to="/reiseberichte">
                  <span class="tag is-medium is-primary is-outlined is-rounded">#Urlaubstipp</span>
                </Link>
                }
                </div>

              </div>
            </div>

          </div>


        </div>

      </Link>
      </div>
    )

    }
  }

  export default PostPreview
