import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PostPreview from "../Posts/post"

const PostList = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulTcContent(sort: {order: DESC, fields: [date]}) {
        edges {
          node {
            title
            subTitle
            date(formatString: "MMMM YYYY", locale: "de")
            type
            teaserText
            slug
            imgLink {
              file {
                url
              }
            }
          }
        }
      }
    }
    `
    }
    render = {data => (
      <div class="columns is-vcentered is-multiline is-centered">

        {data.allContentfulTcContent.edges.map(({node}) => (
          <div class="column">
              <PostPreview
              maxWidth= "400px"
              title = {node.title}
              subTitle = {node.subTitle}
              teaser = {node.teaserText}
              contentfulImg = {node.imgLink.file}
              date = {node.date}
              type = {node.type}
              slug = {node.slug}
              />
          </div>

      ))}
      </div>

    )}
    />
)

export default PostList
