import React from "react"
import Layout from "../components/Layout/layout"
import PostList from "../components/Posts/postList"

const SecondPage = () => (
  <Layout
  heroURL="https://images.unsplash.com/photo-1516426122078-c23e76319801?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&h=900&center=right&q=80"
  title="Reiseberichte"
  subTitle="Unsere Inspirationen für Ihr nächstes Abenteuer!"
  slug = 'Reiseberichte'>
  
  <div style={{padding: "1.5rem"}}>
    <PostList/>
  </div>
  </Layout>
)

export default SecondPage
